import React from 'react'

const ErrorMessage = () => {


    return (
        <div>
            <h2>Der er opstået en fejl ... prøv senere</h2>
        </div>
    )
    
}

export default ErrorMessage