import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom'

import './scss/style.scss';
import Home from './pages/Home';
import Login from './pages/Login';

import DefaultLayout from './layout/DefaultLayout';
import AdminLayout from './layout/AdminLayout';

import ContactAdmin from './pages/admin/ContactAdmin';

import ScrollToTop from './components/ScrollToTop';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      {/* PUBLIC */ }
      <Route path="/" element={ <DefaultLayout /> }>
        <Route index element={ <Home /> } />
        <Route path="login" element={ <Login /> } />
      </Route>

      {/* ADMIN */ }
      <Route path="/admin" element={ <AdminLayout /> }>
        <Route index element={ <ContactAdmin /> } />
      </Route>
    </>

  )
)

function App () {

  return (
    <>
      <ScrollToTop />
      <RouterProvider router={ router } />
    </>
  );
}

export default App;
