import { Link, NavLink } from "react-router-dom"
import Logout from "../components/Logout"

const AdminNavbar = () => {
    return (
        <div className="headerAdminCon">
            <div className="navigationAdminCon">
                <div className="navigationAdmin">
                    <nav>
                        <ul className="menusAdmin">
                            <li>
                                <NavLink
                                    to="/admin"
                                    className={ ( { isActive } ) =>
                                        isActive ? "activeClassName" : undefined
                                    }>
                                    <span className="spanHover">Contact</span>
                                </NavLink>
                            </li>
                            <li><Logout /></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    )
}

export default AdminNavbar