import React, { useEffect } from 'react';
import ErrorMessage from '../../components/ErrorMessage';
import Loader from '../../components/Loader';

import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';

import { useGetData, usePatchData } from '../../hooks/useRequestData';

const ContactAdmin = () => {
  const { error, loading, data, getData } = useGetData();
  const { error: errorPatch, loading: loadingPatch, data: dataPatch, patchData } = usePatchData();

  useEffect(() => {
    getData(process.env.REACT_APP_APIKey + '/contact/admin');
  }, []); // No dependency, so it runs once

  const handleEdit = (e, event) => {
    const id = e._id;
    const newValue = event.target.value === 'true';

    patchData(process.env.REACT_APP_APIKey + '/contact/admin/' + id, { read: newValue });

    // Reload the page
    window.location.reload();
  };

  return (
    <div className='contactAdminCon'>
      <h1 className='adminTitle title'>Administrerer Kontakter</h1>

      {error && <ErrorMessage />}
      {loading && <Loader />}

      <table>
      <thead>
          <tr>
            <th>Navn</th>
            <th>Email</th>
            <th>Emne</th>
            <th>Besked</th>
            <th>Modtaget</th>
            <th>Læst</th>
          </tr>
        </thead>

        <tbody>
          {data &&
            data
              .sort((a, b) => new Date(b.received) - new Date(a.received))
              .map((e, index) => (
                <tr key={e._id} className='contactAdminTable'>
                  <td>{ e.name }</td>
                  <td>{ e.email }</td>
                  <td>{ e.subject }dsadsdasd</td>
                  <td>{ e.message }</td>
                  <td>
                    { new Date( e.received ).toLocaleString( 'da-DK', {
                      year: '2-digit',
                      month: 'short',
                      day: '2-digit',
                    } ) }
                  </td>
                  <td>
                    <select
                      value={e.read}
                      onChange={(event) => handleEdit(e, event)}
                    >
                      <option value={true}>Read</option>
                      <option value={false}>Not Read</option>
                    </select>
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  );
};

export default ContactAdmin;
