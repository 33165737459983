import React from 'react'

import './loader.scss';


const Loader = () => {
  return (
    <div className="loader">      
    </div>
  )
}

export default Loader